@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");

body {
  font-family: "Inconsolata", monospace;
}

.experience-title {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: rgb(217, 49, 48);
}
.experience-time {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: rgb(143, 91, 49);
}
.experience-company {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: rgb(143, 91, 49);
}
.experience-desc {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: rgb(165, 194, 97);
}

.skill-star {
  color: rgb(217, 49, 48);
}

.skill-desc {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: rgb(165, 194, 97);
}

.title {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: rgb(217, 49, 48);
}

.desc {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: rgb(165, 194, 97);
}

.subtitle {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: rgb(143, 91, 49);
}
